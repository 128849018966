@import "~antd/dist/antd.less";
@import "./styles/common.less";
@import "swiper/swiper.less";
@import "swiper/components/navigation/navigation.less";
@import "swiper/components/pagination/pagination.less";
@import "swiper/components/scrollbar/scrollbar.less";
@import "./styles/color.less";
@import "./styles/LoginFlowStyles.less";
@import "./styles/employeeDashboardstyles.less";
@import "./styles/employeeDetails.less";
@import "./styles/employeeDocuments.less";
@import "./styles/employeeListStyles.less";
@import "./styles/employeeLoginFlowStyles.less";
@import "./styles/employeeProfileStyles.less";
@import "./styles/employeeReimbursement.less";
@import "./styles/employeeDocumentAllowance.less";
@import "./styles/employeeDocumentDeduction.less";
@import "./styles/fullAndFinalSettlement.less";
@import "./styles/loginStyles.less";
@import "./styles/monthCloseStyles.less";
@import "./styles/MonthCloseProcessStyles.less";
@import "./styles/SupportStyles.less";
@import "./styles/organisationDashboard.less";
@import "./styles/OrganisationReimbursement.less";
@import "./styles/organisationSettings.less";
@import "./styles/orgConfigStyles.less";
@import "./styles/orgLoginFlowStyles.less";
@import "./styles/organisationReport.less";
@import "./styles/OrgReportsTable.less";
@import "./styles/registerStyles.less";
@import "./styles/termsAndCondition.less";
@import "./styles/completionMessage.less";
@import "./styles/manageDeclarationStyles.less";
@import "./styles/ManageDeclarationAllowance.less";
@import "./styles/ManageDeclarationDeduction.less";
@import "./styles/salaryManageStyles.less";
@import "./styles/addMultipleEmployeePage.less";
@import "./styles/OrganisationDashboardd.less";
@import "./styles/generalStyles.less";
@import "./styles/organisationRunPayroll.less";
@import "./styles/AllInvestementAllowance.less";
@import "./styles/broadcast.less";
@import "./styles/passwordReset.less";
@import "./styles/employeProfile.less";
@import "./styles/OrganisationSettingV2.less";
@import "./styles/allowanceNew.less";
@import "./styles/taxProjection.less";
@import "./styles/DataSync.less";
@import "./styles/typography.less";
@import "./styles/employee.less";
@import "./styles/payroll.less";

.app-heading {
  margin: 20px 0;
}

.app-heading-1 {
  font-size: 32px;
  .app-heading();

  @media (max-width: 600px) {
    font-size: 25px;
  }
}

.app-heading-2 {
  font-size: 24px;
  .app-heading();
}

.app-heading-3 {
  font-size: 20px;
  .app-heading();
}

.app-heading-4 {
  font-size: 18px;
  .app-heading();
}

.app-heading-5 {
  font-size: 16px;
  .app-heading();
}

.app-heading-6 {
  font-size: 14px;
  .app-heading();
}

.app-heading-7 {
  font-size: 12px;
  .app-heading();
}

.title-gray {
  margin: 10px 0;
  font-weight: 300;
  color: #505057;
}

.title-gray-1 {
  font-size: 24px;
  .title-gray();
}

.title-gray-2 {
  font-size: 20px;
  .title-gray();
}

.title-gray-3 {
  font-size: 18px;
  .title-gray();
}

.title-gray-4 {
  font-size: 16px;
  .title-gray();
}

.title-gray-5 {
  font-size: 14px;
  .title-gray();
}

.title-gray-6 {
  font-size: 12px;
  .title-gray();
}

.title-gray-7 {
  font-size: 10px;
  .title-gray();
}

.info-number {
  font-weight: 400;
  color: @color-SharkLite;
  margin: 5px 0;
}

.info-number-1 {
  font-size: 24px;
  .info-number();
}

.info-number-2 {
  font-size: 20px;
  .info-number();
}

.info-number-3 {
  font-size: 18px;
  .info-number();
}

.info-number-4 {
  font-size: 16px;
  .info-number();
}

.info-number-5 {
  font-size: 14px;
  .info-number();
}

.info-number-6 {
  font-size: 12px;
  .info-number();
}

.info-number-7 {
  font-size: 10px;
  .info-number();
}

.text-align-justify {
  text-align: justify;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.app-label-1 {
  color: #505057;
  font-size: 16px;
}

.app-label-2 {
  color: #505057;
  font-size: 16px;
}

.app-label-3 {
  color: #505057;
  font-size: 14px;
}

.app-label-4 {
  color: #505057;
  font-size: 12px;
}

.app-label-5 {
  color: #505057;
  font-size: 18px;
  font-weight: 700;
}

.app-label-value-1 {
  font-size: 16px;
  font-weight: 500;
  margin: 12px 0px;
}

.app-input-value {
  font-size: 18px;
  font-weight: 500;
  color: #5f5f61;
  margin: 7px 0px;
}



.app-input-field-container-1 {
  padding: 16px 0px;
}

.app-input-field-container-2 {
  padding: 8px 0px;
}


.app-select:hover,
.app-select:active,
.app-select:focus {
  border-bottom: @color-Mariner 2px solid;
}

.app-select {
  display: block;
  background-color: @color-AthensGrey;
  border-bottom: @color-AthensGray 2px solid;
}

.app-radio-container {
  height: 28px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.heading-style-2-container {
  padding-bottom: 12px;

  .arrow-left-button {
    margin-right: 24px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .heading-name {
    font-weight: 600;
    font-size: 18px;
  }
}

.heading-style-3-container {
  border-bottom: 2px solid #ededf2;
  padding: 15px 30px;

  .arrow-left-button {
    margin-right: 16px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .heading-name {
    font-weight: 600;
    font-size: 18px;
  }
}

.app-date-picker,
.app-date-picker:hover,
.app-date-picker:active,
.app-date-picker:focus {
  border-bottom: @color-Mariner 2px solid;
}

.app-date-picker,
.app-date-picker:hover,
.app-date-picker:active,
.app-date-picker:focus {
  border-bottom: @color-Mariner 2px solid;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: #000;
  background: #fff;
  cursor: not-allowed;
  font-weight: 400;
}

.app-input-container {
  margin: 32px 0px;
}

.app-input-container-1 {
  margin: 16px 0px;
}

.app-white-card {
  background-color: @color-White;
  padding: 45px;
  border-radius: 10px;
}

.app-white-card-1 {
  background-color: @color-White;
  border-radius: 10px;
}

.app-flex-container-1 {
  .display-center-content-space-between();
  margin-bottom: 16px;
}

.app-flex-container-2 {
  .display-align-center-justify-content-space-around();
  margin-bottom: 16px;
}

.app-flex-container-3 {
  .display-center-content-space-between();
}

.app-breadcrum-container {
  width: 95%;
  margin: 20px auto;

  .app-breadcrum {
    font-size: 16px;
    color: @color-Manatee;
    font-weight: 500;

    .app-breadcrum-active {
      color: @color-Mariner;
    }
  }
}

.app-breadcrum-container-2 {
  width: 95%;

  .app-breadcrum {
    font-size: 16px;
    color: @color-Manatee;
    font-weight: 500;

    .app-breadcrum-active {
      color: @color-Mariner;
    }
  }
}

.submit-button,
.submit-button:active,
.submit-button:focus,
.submit-button:hover {
  background: @color-Shamrock 0% 0% no-repeat padding-box;
  border-radius: 3px;
  border: none;
  height: 40px;
  color: @color-White;
  margin: 0 20px;
}

.edit-button {
  padding: 10px;
  background-color: #405cd2;
  border-radius: 5px;
  color: #fff;
  height: 40px;
  border: none;
}

.edit-button:active,
.edit-button:focus,
.edit-button:hover {
  color: white;
  background-color: #4b6be9;
}

.cancel-button,
.cancel-button:active,
.cancel-button:focus,
.cancel-button:hover {
  background-color: @color-White;
  border-radius: 3px;
  border-color: @color-Alto;
  color: @color-ManateeDark;
  height: 40px;
  margin-right: 10px;
}

.ant-input[disabled],
.ant-input-number-disabled {
  background-color: @color-White;
  color: @color-Shark;
}

.general-input-field,
.general-input-field:active,
.general-input-field:hover,
.general-input-field:focus {
  border: none;
  background-color: @color-White;
  border-bottom: 1px solid @color-AthensGray;
  border-radius: 0px;
  box-shadow: none;
  text-align: left;
  color: @color-Shark;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 1.5rem;
  padding: 12px 2px;
}

.general-input-field-number {
  border: none;
  width: 100%;
  background-color: @color-White;
  border-radius: 0px;
  box-shadow: none;
  text-align: left;
  color: @color-Shark;
  font-weight: 500;
  font-size: 15px;
}

.ant-input-number-input {
  border: none;
  border-radius: 0px;
  box-shadow: none;
  text-align: left;
  color: @color-Shark;
  font-weight: 500;
  font-size: 15px;
  padding: 12px 2px;
}

.ant-input-number-handler-wrap {
  visibility: hidden;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 0px;
}

.ant-select-single.ant-select-open .ant-select-selection-item {
  color: @color-Shark;
}

// .ant-select-disabled.ant-select:not(.ant-select-customize-input)
//   .ant-select-selector {
//   background-color: @color-White;
//   color: @color-Shark;
// }

.ant-select:focus,
.ant-select:hover,
.ant-select:active {
  box-shadow: none;
}

.ant-select:not(.autocomplete):not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: @color-White;
  color: @color-Shark;
  border: none;
  border-bottom: 1px solid @color-AthensGray;
  border-radius: 0px;
  box-shadow: none;
  padding: 0px;
}

.general-select-field,
.general-select-field:hover,
.general-select-field:active,
.general-select-field:focus {
  border: none;
  box-shadow: none;
  text-align: left;
  color: @color-Shark;
  font-weight: 500;
  font-size: 15px;
  padding: 12px 0px;
}

.ant-picker-input {
  .input[disabled] {
    color: @color-Shark;
  }
}

.ant-picker:not(.custom),
.ant-picker-large:not(.custom),
.ant-picker.ant-picker-disabled:not(.custom) {
  background-color: @color-White;
  color: @color-Shark;
  border: none;
  border-bottom: 1px solid @color-AthensGray !important;
  border-radius: 0px;
  box-shadow: none;
  text-align: left;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 1.5rem;
  padding: 12px 2px;
}

.ant-picker-input-placeholder .input {
  color: @color-Shark;
}

.border-buttom {
  border-bottom: 2px solid @color-WildSand;
}

.input-field {
  width: 250px;
  padding: 4.5px 76px 4.5px 10px;
  border-radius: 3px;
  width: 100%;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: @color-SeashellSolid;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: @color-Gray;
  opacity: 0;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: @color-Emperor;
  opacity: 1;
}

.skip-button {
  height: 40px;
  letter-spacing: 0px;
  color: @color-Black;
  margin-left: 20px;
}

.full-width {
  width: 100%;
}

.full-min-height {
  min-height: 100%;
}

.app-main-content-container {
  width: 95%;
  border-radius: 10px;
  margin: 0px auto;
  margin-top: 15px;
}
.section {
  margin: 1rem;
}

.section-top {
  margin-top: 1rem;
}

.section{
  margin: 1rem;
}

.section-top{
  margin-top: 1rem;
}

.document-viewer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.document-display {
  width: 100%;
  height: auto;
}

.pdf-document-action {
  display: flex;
  align-items: center;
  justify-content: space-around;

  p {
    font-weight: 400;
  }
}

.ctc-input {
  background-color: @color-WildSand;
  padding: 12px;
  margin-bottom: 36px;
  border: none;
  width: 100%;
  border-radius: 2px;
  font-size: 16px;
}

.employee-input {
  background: transparent;
  padding: 12px;
  margin-bottom: 24px;
  border: none;
  width: 100%;
  border-radius: 2px;
  font-size: 16px;
  border-bottom: 0.1px solid @color-Manatee;
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: @color-Blue;
  font-size: 14px;
  font-weight: 400;
}

.ant-menu-item a,
.ant-menu-item a:hover {
  font-size: 12px;
  font-weight: 400;
}

.ant-menu-item {
  width: 200;
}

.ant-picker-input > input {
  font-weight: 500;
}

.general-ant-field-label {
  // color: #9898a5;
  font-size: 18px;
  font-weight: 700;
}

.org-data-container > .general-ant-field-label{
  color:#000;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

.general-ant-field-input {
  font-weight: 400;
  padding: 6px 0px;
  background-color: @color-White;
  border-radius: 0px;
  text-align: left;
  color: @color-Shark;
  margin-bottom: 10px;
  border: none;
  box-shadow: none;
  border-bottom: 0.1px solid #eaeaee;
  font-size: 16px;
}

.general-ant-field-input:active,
.general-ant-field-input:hover,
.general-ant-field-input:focus {
  border-bottom: 2px solid #405cd2;
}

.general-ant-field-select {
  font-weight: 400;
  padding: 0px 2px;
  margin-bottom: 10px;
  background-color: @color-White;
  border-radius: 0px;
  text-align: left;
  width: 100%;
  color: @color-Shark;
  border: none;
  box-shadow: none;
  border-bottom: 0.1px solid #eaeaee;
  font-size: 0.8rem;
}

.general-ant-field-select:active,
.general-ant-field-select:focus,
.general-ant-field-select:hover {
  border-bottom: 2px solid #405cd2;
}

.general-ant-field-select:disabled {
  border-bottom: 0.1px solid #eaeaee;
}

.org-data-container {

  .fields-heading {
    color: @color-ManateeLite;
  }

  margin:1.25rem 0;

  .input-field {
    border-radius: 0.2rem;
    outline: none;
    height: 50px;
  }

  .phone-number-input {
    width: 65%;
    display: inline-block;

    .county-code-select {
      width: 15%;
      height: 100%;
      display: inline-block;
      margin-right: 5%;
    }
  }
}

.select-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.individual-structure {
  .display-center-content-space-between();
  width: 100%;
  height: 50px;
  padding: 10px 0;

  .break-label {
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
    line-height: 1.19;
    text-align: left;
    color: @color-MidGray;
  }

  .break-value {
    font-size: 16px;
    line-height: 1.19;
    text-align: left;
    color: @color-MidGray;
  }

  .break-label-earning {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.19;
    text-transform: capitalize;
    text-align: left;
    color: @color-Shamrock;
  }

  .break-value-earning {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.19;
    text-align: left;
    color: @color-Shamrock;
  }

  .break-label-deduction {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.19;
    text-align: left;
    color: @color-Froly;
  }

  .break-value-deduction {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.19;
    text-align: left;
    color: @color-Froly;
  }
}

.takehome {
  color: @color-Denim;
}

.earning {
  color: @color-Shamrock;
}

.deduction {
  color: @color-Froly;
}

.individual-structure-takehome {
  .display-center-content-space-between();
  width: 100%;
  height: 50px;
  padding: 10px 0;

  .break-label {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.19;
    text-align: left;
    color: @color-Denim;
  }

  .break-value {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.19;
    text-align: left;
    color: @color-Denim;
  }
}

.uploaded-docs-card {
  padding: 12px;
  margin: 8px 0px;
  border-radius: 10px;
  display: flex;
  .justify-between();
  .align-center();
  background-color: @color-White;

  .uploaded-docs-left {
    .uploaded-docs-card-name {
      display: inline-block;
      margin-left: 8px;
      font-size: 0.8rem;
      font-weight: 400;
    }
  }
}

.employee-details-container {
  background-color: @color-White;
  padding: 0.5rem 1.5rem;
  border-radius: 10px;
}

.paper-wrapper {
  background-color: @color-White;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 20px;

  .paper-header-container {
    .display-center-content-space-between();

    .paper-heading {
      font-size: 1.5rem;
      font-weight: 500;
    }

    .paper-heading-action {
      .display-center-content-space-between();
    }
  }
}

.bulk-wrapper {
  background-color: @color-White;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 20px;

  .bulk-wrapper-left {
    width: 75%;
    padding: 2px;
    margin-left: 2rem;

    .paper-header-container {
      .display-center-content-space-between();

      .paper-heading {
        font-size: 25px;
        font-weight: 500;
      }

      .paper-title {
        font-size: 16px;
        .title-gray();
        line-height: 1.2;

        .download-employee-list {
          margin: 2px;
          padding: 2px;
          background-color: #fff5e4;
          color: #fea101;
        }
      }

      .paper-heading-action {
        .display-center-content-space-between();
      }
    }

    .bulk-wrapper-dragger {
      border-radius: 10px;
    }

    .button-container {
      margin: 20px 10px;
    }
  }

  .bulk-wrapper-right {
    padding: 5px;
    width: 90%;

    .tips-container {
      line-height: 32px;

      .tips-header {
        font-size: 25px;
        font-weight: 500;
      }

      .tips-title {
        font-size: 14px;
        font-weight: 300;
        color: #90919b;
      }

      .tips-items {
        margin-top: 20px;

        .tips-title {
          font-size: 14px;
          font-weight: 300;
          color: #90919b;
          margin-bottom: 5px;
        }
      }
    }
  }
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.flex {
  display: flex;
}

.code-id{
  background:#b0e8b0; padding: 2px 6px; border-radius:2px 
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex-direction-column {
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.justify-end {
  justify-content: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.justify-around {
  justify-content: space-around;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.ant-table-thead > tr > th {
  color: #6e6e78;
  font-size: 0.875rem;
}

.success-text {
  color: @color-Java;
}

.danger-text {
  color: @color-Froly;
}

.warning-text {
  color: @color-FuelYellow;
}

.info-text {
  color: @color-Denim;
}

.sub-info-text {
  color: @color-Gray;
}

.rounded-image {
  border-radius: 50%;
  object-fit: contain;
}

.rounded-image-1 {
  height: 40px;
  width: 40px;
  .rounded-image();
  object-fit: cover;
}

.rounded-image-2 {
  height: 45px;
  width: 45px;
  .rounded-image();
}

.rounded-image-3 {
  height: 40px;
  width: 40px;
  .rounded-image();
}

.rounded-image-4 {
  height: 35px;
  width: 35px;
  .rounded-image();
}

.rounded-image-5 {
  height: 30px;
  width: 30px;
  .rounded-image();
}

.rounded-image-6 {
  height: 25px;
  width: 25px;
  .rounded-image();
}

.rounded-image-7 {
  height: 20px;
  width: 20px;
  .rounded-image();
}

.w-half {
  width: 50%;
}

.w-quater-past-half {
  width: 75%;
}

.blue-tabs-container {
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    position: relative;
    display: flex;
    transition: transform 0.3s;
    margin-right: 16px;
  }

  .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
    padding: 8px 20px;
    border-radius: 5px;
    width: 200px;
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    background-color: #e1e6ff;
  }

  .ant-tabs-tab .ant-tabs-tab-btn {
    color: #232326;
    font-size: 15px;
    text-shadow: 0 0 0.25px currentColor;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #405cd2;
    text-shadow: 0 0 0.25px currentColor;
  }

  .ant-tabs-ink-bar {
    display: none;
  }
}

.blue-menu-container {
  .ant-menu > .ant-menu-light .ant-menu-root .ant-menu-inline {
    margin-right: 16px;
  }

  .ant-menu-item .ant-menu-item-only-child .ant-menu-item-selected {
    color: #405cd2;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: #eff2ff;
  }

  .ant-menu-item-selected {
    color: #405cd2;
  }

  .ant-menu-item-active {
    color: #405cd2;
  }

  .ant-menu-item {
    border-radius: 5px;
  }

  .ant-menu-submenu-title:hover,
  .ant-menu-submenu-selected {
    color: #405cd2;

    .ant-menu-submenu-arrow::before,
    .ant-menu-submenu-arrow::after {
      color: #405cd2;
    }
  }

  .ant-menu-inline .ant-menu-selected::after,
  .ant-menu-inline .ant-menu-item-selected::after {
    opacity: 1;
  }

  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid #405cd2;
  }

  .ant-menu-submenu:active,
  .ant-menu-submenu:hover {
    .ant-menu-submenu-arrow::before,
    .ant-menu-submenu-arrow::after {
      color: #405cd2;
    }
  }

  .ant-menu-sub.ant-menu-inline {
    background-color: #fff;
  }
}

.error {
  color: red;
  font-size: 0.8rem;
}

.profile-app-white-card {
  background-color: @color-White;
  padding: 35px 0 15px 0;
  border-radius: 10px;
}

.header-menu-slab {
  width: 16rem;
  // padding: 1.5rem;
  border-radius: 10px;
  flex-direction: column;

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    transition: all 0s ease;
  }

  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover {
    background-color: #4d6dee;
    color: white;
  }

  .menu-actions {
    .action-item {
      padding: 0.6rem 1rem;
      cursor: pointer;

      &:hover {
        background-color: #f7f8f8;
      }

      .menu-text {
        color: #3c4043;
        letter-spacing: 0.25px;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 1rem;
        font-weight: 500;
      }
    }
  }

  .header-menu-container {
    flex-direction: column;
    gap: 0.3rem;

    .image-container {
      width: 7rem;
      height: 7rem;
      margin: 0 auto;
      border-radius: 50%;
      background-color: #f0f2f5;
      margin-top: 1.5rem;
    }

    .header-menu-logo {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: scale-down;
    }

    .header-menu-displayName {
      font-weight: 600;
      color: #151b30;
      text-align: center;
      font-size: 0.8rem;
      padding: 5px;
    }

    .header-menu-email {
      font-weight: 300;
      color: #90919b;
      font-size: 0.65rem;
      letter-spacing: 0.4px;
    }

    .header-menu-PAN {
      font-weight: 400;
      color: #90919b;
      font-size: 0.7rem;
    }
  }
}

.header-logo {
  padding: 1.5rem;
  width: 10rem;
  height: 10rem;
}

.header-vertical-line {
  height: 4rem;
  width: 1px;
  background-color: #d5d6df;
}

.profile-header {
  border-bottom: 1px solid #d5d6df;
  width: 100% !important;
  height: 65px !important;
}

.header-label-1 {
  font-size: 1rem;
}

.header-link,
.header-link:visited,
.header-link:active {
  color: #000;
}

.profile-heading {
  font-size: 1.6rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.header-menu-line {
  height: 0.05rem;
  width: 100%;
  margin-top: 1rem;
  background-color: #eaeaee;
}

organisationSettings.less .settings-container {
  background-color: @color-White;
  padding: 3rem;
  border-radius: 10px;
}

.settings-logo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 24px;
}

.organisation-setting-container {
  .settings-container();
  height: 100%;

  .pane-heading {
    .display-center-content-space-between();
    padding-bottom: 16px;
    font-size: 1.5rem;
    font-weight: 500;
  }

  .submit-button-container {
    justify-self: end;
  }

  .main-body-container-2 {
    width: 30%;
  }
}

.employee-profile-container {
  .settings-container();

  .pane-heading {
    padding: 0px 0px 1rem 0px;
    font-size: 1.3em;
    font-weight: 500;
    .display-center-content-space-between();
  }

  .employee-basic-info {
    .display-align-center();
    margin: 10px 0;

    .employee-logo-container {
      margin-right: 2rem;

      .employee-logo {
        background-color: @color-OrangePeel;
        border-radius: 50%;
      }
    }

    .employee-name-container {
      margin-right: 2rem;
    }

    .active-button,
    .active-button:hover {
      border: none;
      background-color: @color-FrostedMint;
      color: @color-Java;
      border-radius: 0.5rem;
    }
  }
}

.avatar-update.ant-upload.ant-upload-select-picture-card {
  width: 500px;
}

.company-data {
  .company-data-cards {
    height: 100%;

    .company-data-card-company-about {
      margin: 32px 0px;
    }

    .company-data-company-code {
      font-size: 48px;
      font-weight: 800;
      margin: 32px 0px;
    }

    .company-data-cards-info {
      color: @color-Shamrock;
    }
  }
}

.company-address-container {
  padding: 42px 48px;
  margin-bottom: 20px;
  height: 100%;

  .media-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .media-heading {
      font-size: 20px;
      font-weight: 500;
    }
  }

  .company-address-individual-data-container {
    margin: 32px 0px;
  }
}

.photo-update-container {
  .display-align-center();

  .current-logo-holder {
    margin-top: 16px;
    margin-right: 2rem;
    width: 60px;
    height: 60px;

    .ant-modal-body {
      .display-content-center();

      .avatar-update {
        display: block;
        width: 500px;
      }
    }

    .upload-container {
      .display-align-center-flex-column();
      justify-self: center;
      height: 100%;
      width: 100%;
      padding: 0;
    }

    .current-logo {
      height: 100%;
    }
  }

  .change-button {
    background-color: @color-Serenade;
    border-radius: 4px;
    border: none;
    color: @color-OrangePeel;
  }
}

.organisation-data-container {
  margin-bottom: 20px;

  .organisation-data-heading-container {
    margin-bottom: 32px;

    .organisation-name-logo-container {
      .display-align-center();
      padding: 0 30px;

      .current-logo-holder-has-logo {
        .display-center-content-space-between();
        width: 4rem;
        border-radius: 50%;

        .default-logo {
          background-color: @color-PeachCream;
          .settings-logo();
        }

        .current-logoo {
          .settings-logo();
          object-fit: scale-down;
          border-radius: 50%;
          width: 4rem !important;
        }
      }
    }
  }
}

.ca-default-background {
  background-color: @color-caGreen;
  border-radius: 50%;
  margin-right: 16px;
  width: 70px;
  height: 70px;
}

.profile-info-container {
  margin-left: 2rem;

  .app-profile-heading {
    font-size: 1rem;
    font-weight: 600;
    color: #151b30;
  }

  .app-profile-subheading {
    font-size: 0.8rem;
    font-weight: 400;
    color: #6e6e78;
  }
}

.org-app-label-1 {
  color: #6e6e78;
  margin: 0.5rem 0;
}

.org-app-bold-label-1 {
  color: #151b30;
  margin: 0.5rem 0;
  font-weight: 600;
}

.blue-submit-button,
.blue-submit-button:active,
.blue-submit-button:focus,
.blue-submit-button:hover {
  border-radius: 3px;
  border: none;
  height: 40px;
  width: 100%;
  color: #405cd2;
  box-shadow: none;
  margin-top: 0;
  padding-top: 0;
}

.org-info-container {
  padding: 0 30px;
}

.get-info-heading {
  font-size: 1.2rem;
  font-weight: 600;
}

.gen-info-header {
  margin-bottom: 2rem;
}

.gen-info-container {
  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 64px;
    margin-right: 2rem;

    .ant-avatar {
      color: #f56a00;
      background-color: #fde3cf;

      .ant-avatar-string {
        font-size: 3rem;
        transform: scale(1) translateX(-50%) translateY(50%) !important;
      }
    }

    .gen-logo {
      object-fit: cover;
      width: 4rem;
      height: 64px;
      border-radius: 50%;
    }
  }
}

.org-profile-heading {
  color: #405cd2;
}

.company-data-card {
  position: relative;
  background-image: linear-gradient(45deg, #3e5acd, #25357a);
  border-radius: 10px;
  padding: 1.5rem;

  .company-card-info {
    h3 {
      color: white;
      z-index: 1;
    }
  }

  .company-card-bg {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 80%;
    bottom: 0;
    left: 0;
    object-fit: cover;
  }

  .company-data-code {
    font-size: 2rem;
    color: white;
    font-weight: 700;
    margin-bottom: 1rem;
    z-index: 1;
  }

  .company-data-card-info {
    width: 75%;
    color: white;
    z-index: 1;
  }
}

.ca-info-container {
  .ca-photo-name-container {
    margin-top: 2rem;

    .img-container {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      margin-right: 1rem;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .ca-info-details {
      h4 {
        font-weight: 700;
        font-size: 1.05rem;
      }

      p {
        color: #696a6e;
        font-size: 0.8rem;
      }
    }
  }

  .ca-pan-details {
    margin-right: 6rem;

    h5 {
      color: #90919b;
    }

    h4 {
      font-weight: 700;
    }
  }
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 2rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-5 {
  margin-left: 2rem;
}

.info-container {
  position: relative;
}

.info-btn {
  outline: none;
  border: none;
  cursor: pointer;
}

.info-card {
  position: absolute;
  display: none;
  height: max-content;
  width: 15rem;
  background-color: black;
  color: white;
  left: 100%;
  border-radius: 7px;
  padding: 0.8rem;
  z-index: 2;

  a,
  a:visited,
  a:active {
    color: #40d2ac;
    cursor: pointer;
  }
}

.info-btn:hover + .info-card,
.info-card:hover {
  display: block;
}

.app-heading-bold {
  .app-heading-2();
  font-weight: 700;
}

.app-heading-blue {
  .app-heading-7();
  font-size: 0.8rem;
  color: #405cd2;
}

.ant-checkbox .ant-checkbox-inner {
  border-color: grey;
}

.ant-checkbox-checked .ant-checkbox-inner {
  border-color: transparent;
}

.backArrow-container {
  border: none;
  background-color: #000;
  margin-left: 0.5rem;
  border-radius: 50%;
  height: 1.7rem;
  width: 1.7rem;
  cursor: pointer;
  position: relative;

  .arrow-left-outline {
    position: absolute;
    top: 5.75px;
    left: 5.75px;
    width: 1rem;
  }
}

.tab-name {
  font-weight: 500 !important;
  font-size: 1rem;
}

.ant-tabs-tab-active {
  .tab-name {
    font-weight: 600;
  }
}

.break-label,
.break-label-deduction,
.break-value-deduction,
.break-label-earning,
.break-value-earning,
.break-value {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.ant-radio-inner {
  border-color: #606063;
}

// .organisation-selection-card {
//   cursor: pointer;
//   // &:hover {
//   //   .org-report-card {
//   //     background-color: #405cd2;
//   //     p {
//   //       color: white;
//   //       font-weight: 700;
//   //     }
//   //   }
//   // }
// }

.cursor-pointer {
  cursor: pointer;
}

label.ant-checkbox-wrapper.ant-checkbox-group-item {
  width: 100%;
  margin: 5px 0;
  align-items: center;
}

.headingstart {
  margin: 30px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.table_border {
  border: 1px solid black;
  border-collapse: collapse;
  width: 100%;

  tr,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
    text-align: left;
    padding: 2px 3px 2px 6px;
  }
}

.tableheading {
  margin-bottom: 0;
}

.font-weight-global-100 {
  font-weight: 100;
}

.font-weight-global-200 {
  font-weight: 200;
}

.font-weight-global-300 {
  font-weight: 300;
}

.font-weight-global-400 {
  font-weight: 400;
}

.font-weight-global-500 {
  font-weight: 500;
}

.font-weight-global-600 {
  font-weight: 600;
}

.font-weight-global-700 {
  font-weight: 700;
}

.font-size-global-24 {
  font-size: 24px;
}

.font-size-global-30 {
  font-size: 30px;
}

.font-size-global-14 {
  font-size: 14px;
}

.font-size-global-18 {
  font-size: 18px;
}

.modal-margin-left-global-2 {
  margin-left: 2.5em;
}

.modal-margin-left-global-heading {
  margin-left: 1.25em;
}

.modal-margin-left-global-small {
  margin-left: 1em;
}

.modal-margin-left-global-half {
  margin-left: 0.5em;
}

.modal-margin-left-global-button {
  margin-left: 1.5em;
}

.modal-margin-left-global-1 {
  margin-left: 1.85em;
}

.modal-alignment-center-global {
  display: flex;
  justify-content: center;
}

.modal-margin-top-reduce-global-2 {
  margin-top: -2em;
}

.modal-margin-top-reduce-global-1 {
  margin-top: -1em;
}

.modal-margin-top-reduce-global-3 {
  margin-top: -3em;
}

.modal-margin-top-add-global-3 {
  margin-top: 3em;
}

.modal-margin-top-add-global-2 {
  margin-top: 2em;
}

.modal-margin-top-add-global-1 {
  margin-top: 1em;
}

.modal-margin-top-add-global-1-half {
  margin-top: 1.5em;
}

.margin-bottom-global {
  margin-bottom: 18px;
}

.break-word {
  word-break: break-all;
}

@primary-color: #405CD2;