.entry-types {
  color: #667085;
  margin-left: 5em;
}

.image-container {
  position: relative;
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
}

.image-container img:nth-child(2) {
  z-index: 1;
  /* place the second image above the first image */
}

.image-container img:nth-child(3) {
  z-index: 2;
  /* place the third image above the second image */
}
.leftImageContainer {
  position: absolute;
  top: 0;
  left: 0;
}
.leftImageContainer img {
  position: absolute;
  top: 0;
  left: 0;
}
.leftImageContainer img:nth-child(2) {
  z-index: 1;
  /* place the second image above the first image */
}

.leftImageContainer img:nth-child(3) {
  z-index: 2;
  /* place the third image above the second image */
}

.rightImageContainer {
  position: relative;
}
.rightImageContainer img {
  position: absolute;
  top: 0;
  left: 0;
}
.rightImageContainer img:nth-child(2) {
  z-index: 1;
  /* place the second image above the first image */
}

.rightImageContainer img:nth-child(3) {
  z-index: 2;
  /* place the third image above the second image */
}

.inner {
  margin-left: 0.75em;
  margin-top: 0.7em;
}

.icon {
  margin-left: 1.65em;
  margin-top: 1.6em;
}

.border-1 {
  border-right: 1px solid #d9d9dc;
  padding-right: 3em;
}

.border-2 {
  border-right: 1px solid #d9d9dc;
  padding-right: 5em;
  margin-left: 3em;
}

.border-3 {
  margin-left: 3em;
}

.grid-div {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .suggestions {
    width: 40em;
    .row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 1em;
      grid-column-gap: 5rem;
    }
    .column {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

.error-found-div {
  margin-right: 25em;
  width: 380px;
  min-width: 350px;
  height: 120px;
  background: #fff7e3;
  border-radius: 12px;
  position: relative;
}
.error-found-div-text {
  color: #ea7810;
  font-size: 32px;
  font-weight: 700;
  display: grid;
  place-content: center;
  padding-top: 8.5%;
}

.smallCircle {
  display: flex;
  border-radius: 100%;
  height: 2em;
  width: 2em;
  background-color: #ffecd0;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

@primary-color: #405CD2;