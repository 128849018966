.audit_cont{
    position: relative;
    display: flex;
    flex-direction: column;

    height:10rem;
    width: 35vw;
    border: 1px solid;
    border-radius: 10px;
    margin-right: 50px;
}

.audit_head{
    display: flex;
    margin: 1rem 1.5rem;
    justify-content: space-between;
}
.audit_head h1{
    font-size: 1.1rem;
    font-weight: 700;
    color: #405CD2;
}
.audit_head button{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 1px solid #D0D5DD;
    border-radius: 6px;
    height: 2.5rem;
    width: 8rem;
}
.audit_head p{
    font-size: 0.85rem;
    font-weight: 700;
    margin-right: 10px;
}
.audit_stats_cont{
    display: flex;
    margin: 1rem 1.5rem;
    justify-content: space-between;
}
.audit_stats p{
    font-size: 0.85rem;
    color: #667085;
}
.audit_stats h1{
    font-size: 1.2rem;
    font-weight: 700;
    color: #16171D;
}
.list_status{
    font-size: 1.2rem;
    font-weight: 700;
}
@primary-color: #405CD2;