.audit_container_zero_state {
  position: relative;
  display: flex;
  flex-direction: column;
  // border: 2px solid red;
  margin: 2rem;
}
.audit_head {
  font-size: 1.2rem;
  font-weight: 700;
  color: #101828;
}

.zero_state_cont {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  margin-top: 2rem;
  border-radius: 12px;
  background: white;
  // border: 2px solid red;
}
.audit_head_zero {
  font-size: 1.2rem;
  font-weight: 700;
  color: #101828;
  margin-top: 1rem;
}
.audit_p {
  font-size: 1rem;
  color: #787b8c;
  margin: 0.2rem 0rem 1rem 0rem;
}

.upload_head {
  font-size: 1.2rem;
  color: #101828;
}
.p_text {
  font-size: 1rem;
  color: #475467;
}
.p_span {
  font-size: 1rem;
  font-weight: 600;
  color: #405cd2;
  background: white;
  border: 0px solid white;
}
.modal_upload_box {
  border-radius: 12px;
  margin: 1rem 0rem;
}
.modal_input_box {
  display: flex;
  flex-direction: column;
}
.modal_input_box p {
  margin-bottom: 10px;
}
.modal_input_box input {
  height: 2.5rem;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
}

// zero state css ends here

.audit_container_data_state {
  position: relative;
  display: flex;
  flex-direction: column;
  // border: 2px solid red;

  margin: 2rem;
}
.audit_list_cont {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
}

@media (min-width: 1450px) {
  .audit_list_cont {
    grid-template-columns: 1fr repeat(1, 1fr);
  }
}
@media (min-width: 1900px) {
  .audit_list_cont {
    grid-template-columns: 1fr repeat(2, 1fr);
  }
}
@media (min-width: 2020px) {
  .audit_list_cont {
    grid-template-columns: 1fr repeat(2, 1fr);
  }
}
@media (min-width: 2500px) {
  .audit_list_cont {
    grid-template-columns: 1fr repeat(3,1fr);
  }
}

@primary-color: #405CD2;